<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-serviceAndContractAssignments')">
                    <el-form-item>
                        <RadioButtonList :entries="serviceAndContractAssignmentsData.texts.options" v-model="selectedValue" :horizontal="false" :disabled="inputDisabled" class="text-sm" />
                    </el-form-item>
                    <el-input v-show="selectedValue === 0" type="textarea" :disabled="inputDisabled" v-model="localInputTextPrincipalRule" class="break-normal custom-textarea" />
                    <el-input v-show="selectedValue === 1" type="textarea" :disabled="inputDisabled" v-model="localInputTextOptionRule" class="break-normal custom-textarea" />
                    <el-input v-show="selectedValue === 2" type="textarea" :disabled="inputDisabled" v-model="localInputTextOptionRuleIndirectCosts" class="break-normal custom-textarea" :rows="3" />
                </div>
            </el-form>
            <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        serviceAndContractAssignmentsData: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            infoContent: `K2 (BFNAR 2016:10) kapitel 18 punkt 5 - Ett företag som utför tjänste- eller entreprenaduppdrag till fast pris ska lämna upplysning om företaget tillämpar huvudregeln eller alternativregeln. 
            <br /><br />
            Huvudregeln och alternativregeln: K2 (BFNAR 2016:10) kapitel 18 punkt 5 - Ett företag som utför tjänste- eller entreprenaduppdrag till fast pris ska lämna upplysning om företaget tillämpar huvudregeln eller alternativregeln. 
            <br /><br />
            Alternativregeln, indirekta utgifter: K2 (BFNAR 2016:10) kapitel 18 punkt 6 - Ett företag som utför tjänste- eller entreprenaduppdrag till fast pris och har räknat in indirekta utgifter i värdet för pågående arbeten för annans räkning ska lämna upplysning om det.`,
            localInputTextPrincipalRule: this.serviceAndContractAssignmentsData.texts.options[0].text,
            localInputTextOptionRule: this.serviceAndContractAssignmentsData.texts.options[1].text,
            localInputTextOptionRuleIndirectCosts: this.serviceAndContractAssignmentsData.texts.options[2].text,
            selectedValue: this.serviceAndContractAssignmentsData.texts.selected,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("../../managementStatement/components/CardEditButtons.vue"),
        InfoButton: () => import("../../managementStatement/components/InfoButton.vue"),
        RadioButtonList: () => import("../../../../components/content/RadioButtonList.vue"),
    },
    watch: {
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 3,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            const { texts } = this.serviceAndContractAssignmentsData;
            texts.selected = this.selectedValue;
            texts.options[0].text = this.localInputTextPrincipalRule;
            texts.options[1].text = this.localInputTextOptionRule;
            texts.options[2].text = this.localInputTextOptionRuleIndirectCosts;
            this.$emit("saveData");
            this.toggleEdit();
            this.$notify.success({ title: 'Sparat "Allmänna upplysningar"' });
        },
        closeModal() {
            this.$emit("close");
            const load = "loading-serviceAndContractAssignments";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
    },
};
</script>
